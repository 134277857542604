export default {
    primary: "#fc5c65",
    secondary: "#4ecdc4",
    black: '#000',
    white: '#fff',
    lightGrey: "#F2F2F2",
    lightRed: "#ff5252",
    grey: "#8b8b8f",
    veryGrey: "#dbd9d7",
    green: "#4bab2e",
    purple: "#9966cc",
    purble2: '#FF63ED',
    purble3: '#DDBEFE',
    purble4: '#4bab2e',
    veryGreen: "#799e3f",
    theme: '#bcf5ee',
    theme2: '#bcf5ee',
    salomon: '#ffa07a',
    orange: '#ff8c00',
    yellow: '#ffdb58',
    purple5: '#800080',


}