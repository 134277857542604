import React from 'react';
import { View } from 'react-native';
import colors from '../colors/colors';
import AppText from './AppText';

function MontageTab({ width, text, mobile }) {
    return (
        <>
            {!mobile && <View style={{ width: width, height: 50, backgroundColor: colors.black, borderRadius: 50, marginHorizontal: 5, justifyContent: 'center' }}>

                <AppText text={text} style={{ color: colors.white }} />

            </View>}

            {mobile && <View style={{ width: width, height: 30, backgroundColor: colors.black, borderRadius: 50, marginHorizontal: 2, justifyContent: 'center' }}>

                <AppText text={text} style={{ color: colors.white, fontSize: 9 }} />

            </View>}
        </>
    );
}

export default MontageTab;