import React from 'react';
import { View, Image, TouchableOpacity } from 'react-native';
import AppText from './AppText';
import colors from '../colors/colors';

function PersonCard({ source, loading, name, onPress, mobile }) {
    return (
        <>
            {!mobile && <TouchableOpacity style={{ width: '20%', height: 400, opacity: loading, alignItems: 'center', marginHorizontal: 20 }} onPress={onPress}>

                <Image style={{ width: 250, height: 250, borderRadius: 150 }} source={source} />

                <AppText text={name} style={{ marginVertical: 20, color: colors.white, fontSize: 30 }} />

            </TouchableOpacity>}

            {mobile && <TouchableOpacity style={{ width: 150, height: 220, opacity: loading, alignItems: 'center', marginVertical: 10 }} onPress={onPress}>

                <Image style={{ width: 150, height: 150, borderRadius: 150 }} source={source} />

                <AppText text={name} style={{ marginVertical: 20, color: colors.white, fontSize: 20, width: '90%' }} />

            </TouchableOpacity>}
        </>
    );
}

export default PersonCard;