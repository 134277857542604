import React from 'react';

import { LinearGradient } from 'expo-linear-gradient';
import { View } from 'react-native-animatable';
import { TouchableOpacity } from 'react-native';
import colors from '../colors/colors';


function CloseIcons({ onPress }) {
    return (
        <TouchableOpacity style={{ position: 'absolute', right: 30, top: 15, justifyContent: 'center', alignItems: 'center', width: 40, height: 40 }} onPress={onPress}>
            <View style={{ height: 40, paddingTop: 20, alignItems: 'center', transform: [{ rotate: "45deg" }], position: 'absolute', width: 4, borderRadius: 5, overflow: 'hidden' }} >
                <LinearGradient
                    colors={['#FF5733', '#A83279']} // Kolorystyka gradientu
                    start={[0, 0]} // Punkt startowy gradientu (x, y)
                    end={[1, 0]}   // Punkt końcowy gradientu (x, y)
                    style={{ height: 40, width: '100%', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 0 }}
                ></LinearGradient>
            </View>

            <View style={{ height: 40, paddingTop: 20, alignItems: 'center', transform: [{ rotate: "315deg" }], position: 'absolute', width: 4, borderRadius: 5, overflow: 'hidden' }} >
                <LinearGradient
                    colors={['#FF5733', '#A83279']} // Kolorystyka gradientu
                    start={[0, 0]} // Punkt startowy gradientu (x, y)
                    end={[1, 0]}   // Punkt końcowy gradientu (x, y)
                    style={{ height: 40, width: '100%', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 0 }}
                ></LinearGradient>
            </View>

        </TouchableOpacity>
    );
}

export default CloseIcons;