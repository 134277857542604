import React from 'react';
import { View, Image } from 'react-native';

const screenWidth = window.innerWidth;
const screenHeight = window.innerHeight;

function LogoComponents(props) {
    return (
        <View>

            <Image style={{ height: screenHeight, width: screenWidth }} resizeMode='contain' source={require("../AppAssets/KrajmStoryLogo.jpg")} />

        </View>
    );
}

export default LogoComponents;