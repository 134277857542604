import React, { useEffect, useRef, useState } from 'react';
import { View, Image, ScrollView, Animated, TouchableOpacity, Linking } from 'react-native';
import colors from '../colors/colors';
import AppText from '../components/AppText';
import LogoComponents from '../components/LogoComponents';
import MontageTab from '../components/MontageTab';

const screenWidth = window.innerWidth;
const screenHeight = window.innerHeight;
const width = window.innerWidth;

import Lottie from "lottie-react";
import PersonCard from '../components/PersonCard';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import CloseIcons from '../components/CloseIcons';

const persons = [

    {
        id: 1,
        name: "JAROSŁAW DOMIN",
        desc: `Jarosław Domin: głos kilkudziesięciu największych polskich produkcji dubbingowych i aktor filmowy. Niezapomniany Obi Wan Kenobi z sagi Gwiezdnych Wojen. Generał Józef Haller w Drodze do morza. Richter w Call of Duty: Black Ops Cold War. W KRAJM STORY Jarek gra głównie policjantów, choć zdarza mu się być również staruszkiem z psem na spacerze.`,
        photo: "https://fwcdn.pl/ppo/05/76/50576/425582.1.jpg",
    },
    {
        id: 2,
        name: "DARIA POLASIK-BUŁKA",
        desc: `Daria Polasik-Bułka: Kraj, Wycinanka, Wiśniowa historia. Daria przebojem wdarła się do świetnych polskich produkcji filmowych, a w KRAJM STORY zasłynęła m.in. brawurową rolą prokuratorki w odcinku KRYPTONIM „ALEA”`,
        photo: "https://media.e-talenta.eu/foto/1717528.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjZmV0IiwiYXVkIjoiY2ZldCIsImlhdCI6MTY0MzA4MTAxMCwiZXhwIjoxNjQzMDg4MjEwLCJkYXRhIjp7ImNkbl9zY29wZSI6ImZvdG8iLCJjZG5faWQiOjE3MTc1MjgsInVzZXJfcHJvZmlsZUlkIjowLCJub0luZGV4IjpmYWxzZX19.n1eB7XJ1L0mJJX1vROX07q9iDf4xcVSv2ohcXpHe-zI",
    },
    {
        id: 3,
        name: "KRZYSZTOF GODLEWSKI",
        desc: `Krzysztof Godlewski: Raport Pileckiego, Komisarz Alex, Erynie, Receptura. A do tego wszystkiego Nagroda im. Zbigniewa Zapasiewicza \"Zapasowa Maska&quot\" za rolę w spektaklu \"Stramer&quot\". Krzysztof umie zagrać wszystko – w KRAJM STORY był już policjantem, przestępcą i świadkiem.`,
        photo: "https://dubbingpedia.pl/w/images/thumb/f/f3/Krzysztof_Godlewski.jpg/300px-Krzysztof_Godlewski.jpg",
    },


]

function MainScreen(props) {



    const [scrollPosition, setScrollPosition] = useState(0);

    const [currentPreson, setCurrentPerson] = useState({ photo: "", name: "", desc: "", id: 1 });

    const valueLayout = useState(new Animated.ValueXY({ x: 0, y: 0 }))[0];

    const valueLayout2 = useState(new Animated.ValueXY({ x: 0, y: 0 }))[0];

    const scrollViewRef = useRef(null);

    const moveCardPatronite = () => {

        Animated.timing(valueLayout, { toValue: { x: 350, y: 0 }, duration: 700, useNativeDriver: false }).start();

    }

    const onSetScroll = (y) => {

        setScrollPosition(y)

        if (y > 3500) {
            moveCardPatronite();
        }
    }

    const [heightOfCard, setHeight] = useState(new Animated.Value(0));

    const [actorCardClicked, setActorCardClicked] = useState(false);

    const handleOpenCard = (actor) => {
        setCurrentPerson(actor);
        setActorCardClicked(true)
        Animated.spring(heightOfCard, {
            toValue: 400, // Docelowa wysokość po powiększeniu, możesz dostosować wartość do potrzeb
            useNativeDriver: false,
        }).start();
    };

    const handleCloseCard = () => {
        setActorCardClicked(false)
        setHeight(new Animated.Value(0));
    };

    return (
        <>

            {width > 650 && <View style={{ width: 60, height: 250, backgroundColor: colors.white, borderRadius: 50, position: 'absolute', top: (screenHeight / 2) - 125, left: 20, zIndex: 1, justifyContent: 'center', alignItems: 'center' }} onPress={() => scrollViewRef.current.scrollTo({ y: screenHeight * 9, animated: true })}>

                <TouchableOpacity onPress={() => Linking.openURL("https://open.spotify.com/show/7miC8gksgqV4Rn2ml92uoR")}>
                    <Image style={{ width: 40, height: 40, resizeMode: 'contain', marginBottom: width < 650 ? 0 : 15, marginVertical: 10 }} source={{ uri: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/19/Spotify_logo_without_text.svg/2048px-Spotify_logo_without_text.svg.png" }} />
                </TouchableOpacity>

                <TouchableOpacity onPress={() => Linking.openURL("https://podcasts.apple.com/us/podcast/krajm-story/id1649564420")}>
                    <Image style={{ width: 40, height: 40, resizeMode: 'contain', marginBottom: width < 650 ? 0 : 15, marginVertical: 10 }} source={{ uri: "https://seeklogo.com/images/A/apple-podcast-logo-0CF661058F-seeklogo.com.png" }} />
                </TouchableOpacity>

                <TouchableOpacity onPress={() => Linking.openURL("https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy50cmFuc2lzdG9yLmZtL2tyYWptLXN0b3J5")}>
                    <Image style={{ width: 40, height: 40, resizeMode: 'contain', marginBottom: width < 650 ? 0 : 15, marginVertical: 10 }} source={{ uri: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/25/Google_Podcasts_icon.svg/1200px-Google_Podcasts_icon.svg.png" }} />
                </TouchableOpacity>

            </View >}

            {width < 650 &&
                <View style={{ position: 'absolute', top: -70, zIndex: 1, alignItems: 'center', width: '100%', opacity: scrollPosition < 4600 ? (6.5 - scrollPosition / 100) : -46 + (scrollPosition / 100) }}>

                    <Animated.View style={[{ overflow: 'hidden', width: '80%', height: 70, transform: [{ translateY: (scrollPosition > screenHeight / 2 && scrollPosition < screenHeight / 2 + 80) ? scrollPosition - screenHeight / 2 : (scrollPosition >= screenHeight / 2 + 80 ? scrollPosition >= screenHeight / 2 + 80 : 0) }], borderRadius: 50, zIndex: 1, justifyContent: 'center', alignItems: 'center' }]} onPress={() => scrollViewRef.current.scrollTo({ y: screenHeight * 9, animated: true })}>
                        <LinearGradient
                            colors={['#8f807f', '#1c1a1a']} // Kolorystyka gradientu
                            start={[0, 0]} // Punkt startowy gradientu (x, y)
                            end={[1, 0]}   // Punkt końcowy gradientu (x, y)
                            style={{ height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 0 }}
                        ></LinearGradient>
                        <AppText text={"SŁUCHAJ NAS NA :"} style={{ color: colors.white }} />
                        <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginVertical: 10 }}>
                            <TouchableOpacity style={{ marginHorizontal: 15 }} onPress={() => Linking.openURL("https://open.spotify.com/show/7miC8gksgqV4Rn2ml92uoR")}>
                                <Image style={{ width: 30, height: 30, resizeMode: 'contain', marginBottom: width < 650 ? 0 : 15 }} source={{ uri: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/19/Spotify_logo_without_text.svg/2048px-Spotify_logo_without_text.svg.png" }} />
                            </TouchableOpacity>

                            <TouchableOpacity style={{ marginHorizontal: 15 }} onPress={() => Linking.openURL("https://podcasts.apple.com/us/podcast/krajm-story/id1649564420")}>
                                <Image style={{ width: 30, height: 30, resizeMode: 'contain', marginBottom: width < 650 ? 0 : 15 }} source={{ uri: "https://seeklogo.com/images/A/apple-podcast-logo-0CF661058F-seeklogo.com.png" }} />
                            </TouchableOpacity>

                            <TouchableOpacity style={{ marginHorizontal: 15 }} onPress={() => Linking.openURL("https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy50cmFuc2lzdG9yLmZtL2tyYWptLXN0b3J5")}>
                                <Image style={{ width: 30, height: 30, resizeMode: 'contain', marginBottom: width < 650 ? 0 : 15 }} source={{ uri: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/25/Google_Podcasts_icon.svg/1200px-Google_Podcasts_icon.svg.png" }} />
                            </TouchableOpacity>
                        </View>
                    </Animated.View>
                </View>}

            <ScrollView style={{ backgroundColor: colors.black }} onScroll={(event) => {
                onSetScroll(event.nativeEvent.contentOffset.y)



            }} scrollEventThrottle={16} onScrollBeginDrag={() => { console.log("elo") }} ref={scrollViewRef}>

                {width > 650 && <View style={{ width: screenWidth, height: screenHeight, alignItems: 'center', paddingTop: 20 }}>

                    <View style={{ width: screenWidth, height: screenHeight * 5 + 1200, alignItems: 'center', paddingTop: 20, position: 'absolute', justifyContent: 'center', zIndex: actorCardClicked ? 1 : 0 }}>
                        <Animated.View style={{
                            width: '40%', height: heightOfCard, borderRadius: 50, position: 'absolute', backgroundColor: colors.grey, overflow: 'hidden', shadowColor: 'grey', shadowOffset: {
                                width: 0,
                                height: 2,
                            }, shadowOpacity: 0.5, shadowRadius: 5, flexDirection: 'row', justifyContent: 'center', alignItems: 'center'
                        }}>

                            <Image style={{ height: '100%', width: '40%' }} resizeMode='cover' source={{ uri: currentPreson.photo }} />
                            <View style={{ marginHorizontal: 30, height: '95%', width: '60%' }}>

                                <AppText text={currentPreson.name} gradient={true} gradientSize={40} center={true} />

                                <AppText text={currentPreson.desc} style={{ color: colors.white, width: "80%", fontSize: 16, textAlign: 'left' }} />

                                <CloseIcons onPress={handleCloseCard} />

                            </View>


                        </Animated.View>
                    </View>

                    <AppText text={"KRAJM STORY"} style={{ color: colors.primary, fontSize: 50 }} />

                    <AppText text={"TEATR W WYDANIU DŹWIĘKOWYM"} style={{ color: colors.white, fontSize: 20, }} />

                    <Image style={{ height: 800, width: screenWidth, opacity: 1 - scrollPosition / 100 }} resizeMode='contain' source={require("../AppAssets/logo.png")} />

                    <View style={{ opacity: -1.5 + (scrollPosition / 100), width: '100%', height: screenHeight, marginVertical: 60, justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 130 }}>
                        <AppText text={"JEDYNY PODCASTOWY TEATR KRYMINALNY"} style={{ color: colors.white, fontSize: 50 }} gradient={true} gradientSize={90} />
                    </View>

                    <View style={{ borderTopWidth: 3, borderTopColor: colors.white, paddingTop: 40, height: 500, width: screenWidth, alignItems: 'center' }}>

                        <AppText text={"KAŻDE PIĘĆ"} style={{ color: colors.white, fontSize: 30 }} />

                        <AppText text={"MINUT"} gradient={true} gradientSize={scrollPosition < 700 ? scrollPosition / 2 : 350} />




                    </View>

                    <View style={{ paddingTop: 40, height: 800, width: screenWidth, alignItems: 'center' }}>

                        <View style={{ opacity: -8 + (scrollPosition / 100), width: '100%', height: 50 }}>
                            <AppText text={"KRAJM STORY"} style={{ color: colors.white, fontSize: 50 }} />
                        </View>

                        <View style={{ opacity: -9 + (scrollPosition / 100), width: '100%', height: 50, marginVertical: 60 }}>
                            <AppText text={"TO"} style={{ color: colors.white, fontSize: 20 }} gradientSize={26} gradient={true} />
                        </View>

                        <View style={{ width: 300, height: 300, backgroundColor: colors.white, borderRadius: 150, marginVertical: 150, alignItems: 'center', justifyContent: 'center' }}>




                            <Animated.View style={{ width: 20, height: 250, transform: [{ rotate: (scrollPosition / 5) + "deg" }], alignItems: 'center' }}>
                                <View style={{ width: 20, height: 150, borderRadius: 30, position: 'absolute', top: 0, backgroundColor: colors.black }}>
                                </View>

                            </Animated.View>


                        </View>
                    </View>

                    <View style={{ paddingTop: 40, height: 800, width: screenWidth, alignItems: 'center' }}>

                        <View style={{ opacity: -17.5 + (scrollPosition / 100), width: '100%', height: 50 }}>
                            <AppText text={"GODZINA PRACY NASZYCH AKTORÓW"} gradient={true} gradientSize={50} />
                            <AppText text={"W KRAJM STORY MOŻESZ USŁYSZEĆ M.IN."} style={{ fontSize: 26, color: colors.white }} />
                        </View>

                        <View style={{ width: '100%', height: 400, alignItems: 'center', justifyContent: 'center', flexDirection: 'row', marginVertical: 100 }}>
                            <PersonCard source={{ uri: persons[0].photo }} name={"JAROSŁAW DOMIN"} loading={-18.5 + (scrollPosition / 100)} onPress={() => handleOpenCard(persons[0])} />

                            <PersonCard source={{ uri: persons[1].photo }} name={"DARIA POLASIK-BUŁKA"} loading={-19 + (scrollPosition / 100)} onPress={() => handleOpenCard(persons[1])} />

                            <PersonCard source={{ uri: persons[2].photo }} name={"KRZYSZTOF GODLEWSKI"} loading={-19.5 + (scrollPosition / 100)} onPress={() => handleOpenCard(persons[2])} />
                        </View>

                        <View style={{ opacity: -22.5 + (scrollPosition / 100), width: '100%', marginBottom: 50 }}>
                            <AppText text={"ORAZ 4 GODZINY MONTAŻU"} gradient={true} gradientSize={50} />
                        </View>

                    </View>

                    <View style={{ height: 700, width: screenWidth, alignItems: 'center' }}>


                        <View style={{ width: '50%', height: 600, borderRadius: 30, backgroundColor: colors.white, overflow: 'hidden', padding: 20, alignItems: 'center', opacity: -26 + (scrollPosition / 100) }}>


                            <Lottie animationData={require("../AppAssets/animations/sound.json")} loop={true} style={{ width: '80%', height: 400 }} />

                            <Animated.View style={{ width: '200%', height: 60, position: 'absolute', bottom: 10, flexDirection: 'row', transform: [{ translateX: scrollPosition < 2950 ? 0 : scrollPosition - 3000 }], justifyContent: 'center' }}>

                                <MontageTab width={'10%'} text={"Partycja "} />

                                <MontageTab width={'15%'} text={"Tajemnica 2"} />

                                <MontageTab width={'5%'} text={"Sąd"} />

                                <MontageTab width={'8%'} text={"Krzyki"} />

                                <MontageTab width={'11%'} text={"Hamowanie"} />

                                <MontageTab width={'15%'} text={"Jedzenie"} />

                                <MontageTab width={'10%'} text={"Włączanie"} />

                                <MontageTab width={'15%'} text={"Odgłosy 5"} />

                                <MontageTab width={'5%'} text={"Bzyk"} />

                                <MontageTab width={'8%'} text={"Auto"} />

                                <MontageTab width={'11%'} text={"Henryk"} />

                                <MontageTab width={'15%'} text={"Telefon"} />

                                <MontageTab width={'10%'} text={"test 1"} />

                                <MontageTab width={'15%'} text={"test 2"} />

                                <MontageTab width={'5%'} text={"test 2"} />

                                <MontageTab width={'8%'} text={"test 2"} />

                                <MontageTab width={'11%'} text={"test 2"} />

                                <MontageTab width={'15%'} text={"test 2"} />

                            </Animated.View>

                            <Animated.View style={{ width: '200%', height: 60, position: 'absolute', bottom: 80, flexDirection: 'row', transform: [{ translateX: scrollPosition < 2900 ? 0 : -(scrollPosition - 2950) }], justifyContent: 'center' }}>

                                <MontageTab width={'10%'} text={"Komendant"} />

                                <MontageTab width={'15%'} text={"Dźwięk 3"} />

                                <MontageTab width={'5%'} text={"Pacjent"} />

                                <MontageTab width={'8%'} text={"Las 4"} />

                                <MontageTab width={'11%'} text={"Skrzypienie"} />

                                <MontageTab width={'15%'} text={"Drzwi"} />

                                <MontageTab width={'10%'} text={"Komisarz"} />

                                <MontageTab width={'15%'} text={"Kacper 1"} />

                                <MontageTab width={'5%'} text={"Oficer"} />

                                <MontageTab width={'8%'} text={"Myślenie 3"} />

                                <MontageTab width={'11%'} text={"Operacja 8"} />

                                <MontageTab width={'15%'} text={"Scena  5"} />

                                <MontageTab width={'10%'} text={"Krzysztof "} />

                                <MontageTab width={'15%'} text={"Zwierzęta"} />

                                <MontageTab width={'5%'} text={"Szum"} />

                                <MontageTab width={'8%'} text={"Wspieraj"} />

                                <MontageTab width={'11%'} text={"Na"} />

                                <MontageTab width={'15%'} text={"Patronite"} />

                            </Animated.View>


                        </View>




                    </View>


                    <View style={{ width: screenWidth, alignItems: 'center' }}>

                        <View style={{ opacity: -34 + (scrollPosition / 100), width: '100%', height: 50, marginVertical: 50 }}>
                            <AppText text={"ALE TAKŻE"} style={{ color: colors.white, fontSize: 23 }} />
                        </View>

                        <View style={{ opacity: -35 + (scrollPosition / 100), width: '100%' }}>
                            <AppText text={"6"} gradient={true} gradientSize={300} />
                        </View>

                        <View style={{ opacity: -36 + (scrollPosition / 100), width: '100%', height: 50, marginVertical: 50 }}>
                            <AppText text={"STRON SCERNARIUSZA"} style={{ color: colors.white, fontSize: 23 }} />
                        </View>

                        <Animated.View style={{ width: '35%', height: 700, backgroundColor: colors.white, opacity: -37 + (scrollPosition / 100), padding: 50, paddingTop: 20 }}>

                            <View style={{ opacity: -38 + (scrollPosition / 100), width: '100%' }}>
                                <AppText text={"KRAJM STORY"} style={{ color: colors.primary, fontSize: 40 }} />
                            </View>

                            <View style={{ overflow: 'hidden', width: '90%', height: '90%' }}>

                                <Animated.View style={{ width: '100%', position: 'absolute', right: '70%', height: 20, backgroundColor: colors.black, borderRadius: 30, marginVertical: 20, transform: [{ translateX: scrollPosition < 3800 ? 0 : scrollPosition - 3800 }] }}>


                                </Animated.View>

                                <Animated.View style={{ width: '100%', position: 'absolute', top: 60, right: '60%', height: 20, backgroundColor: colors.black, borderRadius: 30, marginVertical: 20, transform: [{ translateX: scrollPosition < 3900 ? 0 : scrollPosition - 3900 }] }}>


                                </Animated.View>

                                <Animated.View style={{ width: '100%', position: 'absolute', top: 120, right: '70%', height: 20, backgroundColor: colors.black, borderRadius: 30, marginVertical: 20, transform: [{ translateX: scrollPosition < 4000 ? 0 : scrollPosition - 4000 }] }}>


                                </Animated.View>

                                <Animated.View style={{ width: '100%', position: 'absolute', top: 180, right: '90%', height: 20, backgroundColor: colors.black, borderRadius: 30, marginVertical: 20, transform: [{ translateX: scrollPosition < 4100 ? 0 : scrollPosition - 4100 }] }}>


                                </Animated.View>

                                <Animated.View style={{ width: '100%', position: 'absolute', top: 240, right: '80%', height: 20, backgroundColor: colors.black, borderRadius: 30, marginVertical: 20, transform: [{ translateX: scrollPosition < 4200 ? 0 : scrollPosition - 4200 }] }}>


                                </Animated.View>

                                <Animated.View style={{ width: '100%', position: 'absolute', top: 300, right: '70%', height: 20, backgroundColor: colors.black, borderRadius: 30, marginVertical: 20, transform: [{ translateX: scrollPosition < 4300 ? 0 : scrollPosition - 4300 }] }}>


                                </Animated.View>


                                <Animated.View style={{ width: '100%', position: 'absolute', top: 360, right: '70%', height: 20, backgroundColor: colors.black, borderRadius: 30, marginVertical: 20, transform: [{ translateX: scrollPosition < 4300 ? 0 : scrollPosition - 4300 }] }}>


                                </Animated.View>


                                <Animated.View style={{ width: '100%', position: 'absolute', top: 420, right: '90%', height: 20, backgroundColor: colors.black, borderRadius: 30, marginVertical: 20, transform: [{ translateX: scrollPosition < 4400 ? 0 : scrollPosition - 4400 }] }}>


                                </Animated.View>


                                <Animated.View style={{ width: '100%', position: 'absolute', top: 480, right: '80%', height: 20, backgroundColor: colors.black, borderRadius: 30, marginVertical: 20, transform: [{ translateX: scrollPosition < 4500 ? 0 : scrollPosition - 4500 }] }}>


                                </Animated.View>

                            </View>



                        </Animated.View>

                    </View>


                    <View style={{ width: screenWidth, alignItems: 'center' }}>
                        <View style={{ opacity: -46 + (scrollPosition / 100), width: '100%', height: 50, marginVertical: 50 }}>
                            <AppText text={"to tak jakbyś przescrollował tę stronę"} style={{ color: colors.white, fontSize: 23 }} />
                        </View>

                        <View style={{ opacity: -47 + (scrollPosition / 100), width: '100%' }}>
                            <AppText text={Math.round(scrollPosition * 4) < 20000 ? Math.round(scrollPosition * 4) : 20000} gradient={true} gradientSize={300} />
                        </View>
                    </View>


                    <View style={{ paddingTop: 40, height: 900, width: screenWidth, alignItems: 'center' }}>



                        <View style={{ opacity: -50 + (scrollPosition / 100), width: '100%', height: 50 }}>
                            <AppText text={"razy, dlatego"} style={{ color: colors.white, fontSize: 23 }} />
                        </View>

                        <View style={{ opacity: -51 + (scrollPosition / 100), width: '100%' }}>
                            <AppText text={"WESPRZYJ"} gradient={true} gradientSize={scrollPosition < 5550 ? scrollPosition / 15.857 : 350} />
                        </View>

                        <View style={{ opacity: -55 + (scrollPosition / 100), width: '100%' }}>
                            <AppText text={"KRAJM STORY W SERWISIE"} style={{ color: colors.white, fontSize: 23 }} />
                        </View>

                        <TouchableOpacity onPress={() => Linking.openURL("https://patronite.pl/krajmstory")} style={{ width: 300, height: 80, borderRadius: 50, justifyContent: 'center', alignItems: 'center', marginVertical: 60, backgroundColor: colors.primary, overflow: 'hidden' }}>



                            <AppText text={"Patronite"} style={{ color: colors.white, fontSize: 23 }} />


                            <Animated.View style={{ width: 70, height: 500, transform: [{ rotate: 20 + "deg" }, { translateX: scrollPosition < 5700 ? 0 : scrollPosition - 5750 }], backgroundColor: colors.white, opacity: 0.6, position: 'absolute', left: 0 }}>


                            </Animated.View>


                        </TouchableOpacity>

                    </View>

                    <View style={{ paddingTop: 40, width: screenWidth, alignItems: 'center', borderTopWidth: 3, borderTopColor: colors.white, paddingTop: 20 }}>


                        <AppText text={"TEATR POLSKIEGO PODCASTU"} gradient={true} gradientSize={100} />

                        <AppText text={`Teatr Polskiego Podcastu to grupa osób, które wierzą, że dobry podcast to coś więcej niż głos. To również dźwięk, muzyka, atmosfera i przede wszystkim - wyobraźnia słuchacza. Sięgamy po fascynujące, tylko prawdziwe historie i opowiadamy je tak, jak sami chcielibyśmy je usłyszeć.  

​

Współpracujemy z najlepszymi polskimi aktorami dubbingowymi, genialnymi dźwiękowcami i wspaniałymi scenarzystami. Nad każdym odcinkiem pracujemy długimi tygodniami, dopieszczając każdy szmer, kłócąc się o prawidłowy dźwięk odsuwanego krzesła i sprawdzając, jak długość trawy wpływa na odgłos kroków. Wszystko po to, aby nasze podcasty w pełni przenosiły słuchaczy w swoje historie. To słychać, prawda?

​

"Krajm story" to pierwsza seria podcastów Teatru Polskiego Podcastu. Opowiadamy historie znakomicie opisane przez Ewę Ornacką, gwiazdę polskiego reportażu kryminalnego. Stworzyliśmy serię podcastów, jakich jeszcze w Polsce nie było - opowiadanych z perspektywy policjantów, którzy rozwiązywali najgłośniejsze sprawy w kraju. 

​

Nasze podcasty są za darmo. Jeśli spodobają się, możesz zostać naszym`} style={{ color: colors.white, fontSize: 16, width: '60%' }} />

                        <TouchableOpacity onPress={() => Linking.openURL("https://patronite.pl/krajmstory")} style={{ width: 300, marginVertical: 20, height: 80, borderRadius: 50, justifyContent: 'center', alignItems: 'center', marginVertical: 30, backgroundColor: colors.primary, overflow: 'hidden' }}>



                            <AppText text={"Patronem"} style={{ color: colors.white, fontSize: 23 }} />


                            <Animated.View style={{ width: 70, height: 500, transform: [{ rotate: 20 + "deg" }, { translateX: scrollPosition < 6400 ? 0 : scrollPosition - 6400 }], backgroundColor: colors.white, opacity: 0.6, position: 'absolute', left: 0 }}>


                            </Animated.View>


                        </TouchableOpacity>

                    </View>



                    <View style={{ paddingTop: 40, height: 300, width: screenWidth, alignItems: 'center', borderTopWidth: 3, borderTopColor: colors.white, paddingTop: 20, backgroundColor: colors.primary }}>



                        <LinearGradient
                            colors={['#fc5c65', '#bf8e91']} // Kolorystyka gradientu
                            start={[0, 0]} // Punkt startowy gradientu (x, y)
                            end={[1, 0]}   // Punkt końcowy gradientu (x, y)
                            style={{ height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 0 }}
                        ></LinearGradient>

                        <AppText text={"podcast@krajmstory.pl"} style={{ color: colors.white, fontSize: 30 }} />

                        <AppText text={"© 2023 Teatr Polskiego Podcastu"} style={{ color: colors.white, fontSize: 16, position: 'absolute', bottom: 10 }} />

                    </View>



                </View>}

                {width < 650 && <View style={{ width: screenWidth, height: screenHeight, alignItems: 'center', paddingTop: 20 }}>


                    <AppText text={"KRAJM STORY"} style={{ color: colors.primary, fontSize: 45 }} />

                    <AppText text={"TEATR W WYDANIU DŹWIĘKOWYM"} style={{ color: colors.white, fontSize: 17, width: '90%' }} />

                    <Image style={{ height: screenHeight, width: screenWidth, opacity: 1 - scrollPosition / 100 }} resizeMode='contain' source={require("../AppAssets/logo.png")} />

                    <View style={{ opacity: -1.5 + (scrollPosition / 100), width: '100%', height: screenHeight, marginVertical: 60, justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 200 }}>
                        <AppText text={"JEDYNY PODCASTOWY TEATR KRYMINALNY"} style={{ color: colors.white, fontSize: 30 }} gradient={true} gradientSize={30} />
                    </View>


                    <View style={{ borderTopWidth: 3, borderTopColor: colors.white, paddingTop: 40, height: 200, width: screenWidth, alignItems: 'center' }}>

                        <AppText text={"KAŻDE PIĘĆ"} style={{ color: colors.white, fontSize: 20 }} />

                        <AppText text={"MINUT"} gradient={true} gradientSize={scrollPosition < 700 ? scrollPosition / 7.77 : 90} />

                    </View>


                    <View style={{ paddingTop: 40, height: 600, width: screenWidth, alignItems: 'center' }}>

                        <View style={{ opacity: -7 + (scrollPosition / 100), width: '100%', height: 50 }}>
                            <AppText text={"KRAJM STORY"} style={{ color: colors.white, fontSize: 30 }} />
                        </View>

                        <View style={{ opacity: -8 + (scrollPosition / 100), width: '100%', height: 50, marginVertical: 60 }}>
                            <AppText text={"TO"} style={{ color: colors.white, fontSize: 16 }} gradient={true} gradientSize={25} />
                        </View>

                        <View style={{ width: 200, height: 200, backgroundColor: colors.white, borderRadius: 150, marginVertical: 50, alignItems: 'center', justifyContent: 'center', opacity: -9 + (scrollPosition / 100) }}>




                            <Animated.View style={{ width: 15, height: 170, transform: [{ rotate: (scrollPosition / 2) + "deg" }], alignItems: 'center' }}>
                                <View style={{ width: 15, height: 85, borderRadius: 30, position: 'absolute', top: 0, backgroundColor: colors.black }}>
                                </View>

                            </Animated.View>


                        </View>
                    </View>

                    <View style={{ width: screenWidth, alignItems: 'center' }}>

                        <View style={{ opacity: -12 + (scrollPosition / 100), width: '90%', marginBottom: 30 }}>
                            <AppText text={"GODZINA PRACY NASZYCH AKTORÓW"} gradient={true} gradientSize={30} />
                            <AppText text={"W KRAJM STORY MOŻESZ USŁYSZEĆ M.IN."} style={{ fontSize: 13, color: colors.white }} />
                        </View>

                        <View style={{ width: '100%', alignItems: 'center' }}>

                            <PersonCard mobile={true} source={{ uri: persons[0].photo }} name={"JAROSŁAW DOMIN"} loading={-13 + (scrollPosition / 100)} />

                            <PersonCard mobile={true} source={{ uri: persons[1].photo }} name={"DARIA POLASIK-BUŁKA"} loading={-16 + (scrollPosition / 100)} />

                            <PersonCard mobile={true} source={{ uri: persons[2].photo }} name={"KRZYSZTOF GODLEWSKI"} loading={-19 + (scrollPosition / 100)} />
                        </View>


                    </View>


                    <View style={{ width: screenWidth, alignItems: 'center', paddingTop: 50 }}>


                        <View style={{ opacity: -21 + (scrollPosition / 100), width: '100%', height: 50, marginBottom: 50 }}>
                            <AppText text={"ORAZ 4 GODZINY MONTAŻU"} style={{ color: colors.white, fontSize: 1 }} gradient={true} gradientSize={30} />
                        </View>


                        <View style={{ width: '90%', height: 300, borderRadius: 30, backgroundColor: colors.white, overflow: 'hidden', alignItems: 'center', opacity: -22 + (scrollPosition / 100) }}>


                            <Lottie animationData={require("../AppAssets/animations/sound.json")} loop={true} style={{ width: '70%', height: 200 }} />

                            <Animated.View style={{ width: '200%', height: 30, position: 'absolute', bottom: 5, flexDirection: 'row', transform: [{ translateX: scrollPosition < 2000 ? 0 : (scrollPosition) - 2350 }], justifyContent: 'center' }}>

                                <MontageTab mobile={true} width={'10%'} text={"Partycja "} />

                                <MontageTab mobile={true} width={'15%'} text={"Tajemnica 2"} />

                                <MontageTab mobile={true} width={'5%'} text={"Sąd"} />

                                <MontageTab mobile={true} width={'8%'} text={"Krzyki"} />

                                <MontageTab mobile={true} width={'11%'} text={"Hamowanie"} />

                                <MontageTab mobile={true} width={'15%'} text={"Jedzenie"} />

                                <MontageTab mobile={true} width={'10%'} text={"Włączanie"} />

                                <MontageTab mobile={true} width={'15%'} text={"Odgłosy 5"} />

                                <MontageTab mobile={true} width={'5%'} text={"Auto"} />

                                <MontageTab mobile={true} width={'8%'} text={"Henryk"} />

                                <MontageTab mobile={true} width={'11%'} text={"Bzyk"} />

                                <MontageTab mobile={true} width={'15%'} text={"Telefon"} />

                                <MontageTab mobile={true} width={'10%'} text={"test 1"} />

                                <MontageTab mobile={true} width={'15%'} text={"test 2"} />

                                <MontageTab mobile={true} width={'5%'} text={"test 2"} />

                                <MontageTab mobile={true} width={'8%'} text={"test 2"} />

                                <MontageTab mobile={true} width={'11%'} text={"test 2"} />

                                <MontageTab mobile={true} width={'15%'} text={"test 2"} />

                            </Animated.View>

                            <Animated.View style={{ width: '200%', height: 30, position: 'absolute', bottom: 40, flexDirection: 'row', transform: [{ translateX: scrollPosition < 2100 ? 0 : -(scrollPosition - 2450) }], justifyContent: 'center' }}>

                                <MontageTab mobile={true} width={'10%'} text={"Komendant"} />

                                <MontageTab mobile={true} width={'15%'} text={"Dźwięk 3"} />

                                <MontageTab mobile={true} width={'5%'} text={"Pacjent"} />

                                <MontageTab mobile={true} width={'8%'} text={"Las 4"} />

                                <MontageTab mobile={true} width={'11%'} text={"Skrzypienie"} />

                                <MontageTab mobile={true} width={'15%'} text={"Drzwi"} />

                                <MontageTab mobile={true} width={'10%'} text={"Komisarz"} />

                                <MontageTab mobile={true} width={'15%'} text={"Kacper 1"} />

                                <MontageTab mobile={true} width={'5%'} text={"Oficer"} />

                                <MontageTab mobile={true} width={'8%'} text={"Myślenie 3"} />

                                <MontageTab mobile={true} width={'11%'} text={"Operacja 8"} />

                                <MontageTab mobile={true} width={'15%'} text={"Scena  5"} />

                                <MontageTab mobile={true} width={'10%'} text={"Krzysztof "} />

                                <MontageTab mobile={true} width={'15%'} text={"Zwierzęta"} />

                                <MontageTab mobile={true} width={'5%'} text={"Szum"} />

                                <MontageTab mobile={true} width={'8%'} text={"Wspieraj"} />

                                <MontageTab mobile={true} width={'11%'} text={"Na"} />

                                <MontageTab mobile={true} width={'15%'} text={"Patronite"} />

                            </Animated.View>


                        </View>




                    </View>


                    <View style={{ width: screenWidth, alignItems: 'center' }}>

                        <View style={{ opacity: -24 + (scrollPosition / 100), width: '100%', height: 50, marginVertical: 50 }}>
                            <AppText text={"ALE TAKŻE"} style={{ color: colors.white, fontSize: 23 }} />
                        </View>

                        <View style={{ opacity: -25 + (scrollPosition / 100), width: '100%' }}>
                            <AppText text={"6"} gradient={true} gradientSize={150} />
                        </View>

                        <View style={{ opacity: -26 + (scrollPosition / 100), width: '100%', height: 50, marginVertical: 50 }}>
                            <AppText text={"STRON SCERNARIUSZA"} style={{ color: colors.white, fontSize: 23 }} />
                        </View>

                        <Animated.View style={{ width: '90%', height: 530, backgroundColor: colors.white, opacity: -27 + (scrollPosition / 100), padding: 50, paddingTop: 20 }}>

                            <View style={{ opacity: -28 + (scrollPosition / 100), width: '100%' }}>
                                <AppText text={"KRAJM STORY"} style={{ color: colors.primary, fontSize: 40 }} />
                            </View>

                            <View style={{ overflow: 'hidden', width: '95%', height: '90%' }}>

                                <Animated.View style={{ width: '100%', position: 'absolute', right: '70%', height: 20, backgroundColor: colors.black, borderRadius: 30, marginVertical: 20, transform: [{ translateX: scrollPosition < 2800 ? 0 : scrollPosition - 2800 }] }}>


                                </Animated.View>

                                <Animated.View style={{ width: '100%', position: 'absolute', top: 60, right: '60%', height: 20, backgroundColor: colors.black, borderRadius: 30, marginVertical: 20, transform: [{ translateX: scrollPosition < 2900 ? 0 : scrollPosition - 2900 }] }}>


                                </Animated.View>

                                <Animated.View style={{ width: '100%', position: 'absolute', top: 120, right: '70%', height: 20, backgroundColor: colors.black, borderRadius: 30, marginVertical: 20, transform: [{ translateX: scrollPosition < 3000 ? 0 : scrollPosition - 3000 }] }}>


                                </Animated.View>

                                <Animated.View style={{ width: '100%', position: 'absolute', top: 180, right: '90%', height: 20, backgroundColor: colors.black, borderRadius: 30, marginVertical: 20, transform: [{ translateX: scrollPosition < 3100 ? 0 : scrollPosition - 3100 }] }}>


                                </Animated.View>

                                <Animated.View style={{ width: '100%', position: 'absolute', top: 240, right: '80%', height: 20, backgroundColor: colors.black, borderRadius: 30, marginVertical: 20, transform: [{ translateX: scrollPosition < 3200 ? 0 : scrollPosition - 3200 }] }}>


                                </Animated.View>

                                <Animated.View style={{ width: '100%', position: 'absolute', top: 300, right: '70%', height: 20, backgroundColor: colors.black, borderRadius: 30, marginVertical: 20, transform: [{ translateX: scrollPosition < 3300 ? 0 : scrollPosition - 3300 }] }}>


                                </Animated.View>


                                <Animated.View style={{ width: '100%', position: 'absolute', top: 360, right: '70%', height: 20, backgroundColor: colors.black, borderRadius: 30, marginVertical: 20, transform: [{ translateX: scrollPosition < 3300 ? 0 : scrollPosition - 3300 }] }}>


                                </Animated.View>

                            </View>



                        </Animated.View>

                    </View>



                    <View style={{ paddingTop: 40, width: screenWidth, alignItems: 'center' }}>

                        <View style={{ opacity: -34 + (scrollPosition / 100), width: '90%', height: 50, marginVertical: 50, alignItems: 'center' }}>
                            <AppText text={"TO TAK JAKBYŚ PRZESCROLLOWAŁ TĘ STRONE"} style={{ color: colors.white, fontSize: 20, width: '90%' }} />
                        </View>

                        <View style={{ opacity: -35 + (scrollPosition / 100), width: '100%' }}>
                            <AppText text={Math.round(scrollPosition * 5, 555) < 20000 ? Math.round(scrollPosition * 5, 555) : 20000} gradient={true} gradientSize={100} />
                        </View>

                        <View style={{ opacity: -37 + (scrollPosition / 100), width: '100%', height: 50 }}>
                            <AppText text={"RAZY, DLATEGO"} style={{ color: colors.white, fontSize: 17 }} />
                        </View>

                        <View style={{ opacity: -38 + (scrollPosition / 100), width: '100%', marginVertical: 20 }}>
                            <AppText text={"WESPRZYJ"} gradient={true} gradientSize={scrollPosition < 3900 ? scrollPosition / 43.3 : 90} />
                        </View>

                        <View style={{ opacity: -39 + (scrollPosition / 100), width: '100%', marginVertical: 20, alignItems: 'center  ' }}>
                            <AppText text={"KRAJM STORY W SERWISIE"} style={{ color: colors.white, fontSize: 20, width: '90%' }} />
                        </View>

                        <TouchableOpacity onPress={() => Linking.openURL("https://patronite.pl/krajmstory")} style={{ width: '80%', height: 60, borderRadius: 50, justifyContent: 'center', alignItems: 'center', marginVertical: 30, backgroundColor: colors.primary, overflow: 'hidden' }}>



                            <AppText text={"Patronite"} style={{ color: colors.white, fontSize: 23 }} />


                            <Animated.View style={{ width: 70, height: 500, transform: [{ rotate: 20 + "deg" }, { translateX: scrollPosition < 4000 ? 0 : scrollPosition - 4000 }], backgroundColor: colors.white, opacity: 0.6, position: 'absolute', left: 0 }}>


                            </Animated.View>


                        </TouchableOpacity>

                    </View>

                    <View style={{ paddingTop: 40, width: screenWidth, alignItems: 'center', borderTopWidth: 3, borderTopColor: colors.white, paddingTop: 20 }}>


                        <AppText text={"TEATR POLSKIEGO PODCASTU"} gradient={true} gradientSize={35} />

                        <AppText text={`Teatr Polskiego Podcastu to grupa osób, które wierzą, że dobry podcast to coś więcej niż głos. To również dźwięk, muzyka, atmosfera i przede wszystkim - wyobraźnia słuchacza. Sięgamy po fascynujące, tylko prawdziwe historie i opowiadamy je tak, jak sami chcielibyśmy je usłyszeć.  

​

Współpracujemy z najlepszymi polskimi aktorami dubbingowymi, genialnymi dźwiękowcami i wspaniałymi scenarzystami. Nad każdym odcinkiem pracujemy długimi tygodniami, dopieszczając każdy szmer, kłócąc się o prawidłowy dźwięk odsuwanego krzesła i sprawdzając, jak długość trawy wpływa na odgłos kroków. Wszystko po to, aby nasze podcasty w pełni przenosiły słuchaczy w swoje historie. To słychać, prawda?

​

"Krajm story" to pierwsza seria podcastów Teatru Polskiego Podcastu. Opowiadamy historie znakomicie opisane przez Ewę Ornacką, gwiazdę polskiego reportażu kryminalnego. Stworzyliśmy serię podcastów, jakich jeszcze w Polsce nie było - opowiadanych z perspektywy policjantów, którzy rozwiązywali najgłośniejsze sprawy w kraju. 

​

Nasze podcasty są za darmo. Jeśli spodobają się, możesz zostać naszym`} style={{ color: colors.white, fontSize: 10, width: '90%' }} />

                        <TouchableOpacity onPress={() => Linking.openURL("https://patronite.pl/krajmstory")} style={{ width: '80%', marginVertical: 20, height: 60, borderRadius: 50, justifyContent: 'center', alignItems: 'center', marginVertical: 30, backgroundColor: colors.primary, overflow: 'hidden' }}>



                            <AppText text={"Patronem"} style={{ color: colors.white, fontSize: 23 }} />


                            <Animated.View style={{ width: 70, height: 500, transform: [{ rotate: 20 + "deg" }, { translateX: scrollPosition < 4500 ? 0 : scrollPosition - 4500 }], backgroundColor: colors.white, opacity: 0.6, position: 'absolute', left: 0 }}>


                            </Animated.View>


                        </TouchableOpacity>

                    </View>

                    <View style={{ paddingTop: 40, height: 200, width: screenWidth, alignItems: 'center', borderTopWidth: 3, borderTopColor: colors.white, paddingTop: 20, backgroundColor: colors.primary }}>



                        <LinearGradient
                            colors={['#fc5c65', '#bf8e91']} // Kolorystyka gradientu
                            start={[0, 0]} // Punkt startowy gradientu (x, y)
                            end={[1, 0]}   // Punkt końcowy gradientu (x, y)
                            style={{ height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 0 }}
                        ></LinearGradient>

                        <AppText text={"podcast@krajmstory.pl"} style={{ color: colors.white, fontSize: 20 }} />

                        <AppText text={"© 2023 Teatr Polskiego Podcastu"} style={{ color: colors.white, fontSize: 13, position: 'absolute', bottom: 10 }} />

                    </View>


                </View>}
            </ScrollView >
        </>
    );
}

export default MainScreen;